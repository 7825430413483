import React, { useEffect, useState, useContext } from "react";
import { Box, IconButton, styled, Typography } from "@mui/material";
import TimerIcon from "@mui/icons-material/Timer";
import WestIcon from "@mui/icons-material/West";
import { IncTestContext } from "./IncTestContext";
import { useTranslation } from "react-i18next";

const SideA: any = styled(Box, {
  shouldForwardProp: (props) => props !== "onBoarding",
})(({ theme, onboarding }: any) => ({
  background: onboarding ? "none !important" : theme.palette.gradients.inc_test,
  borderRadius: theme.shape.borderRadius10,
  transition: "width 0.3s ease",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    width: "545px",
    minHeight: "70vh",
  },
  position: "relative",
  width: onboarding ? "100% !important" : "auto",
  height: onboarding ? "100% !important" : "auto",
}));

const FontTypography = styled(Typography)({
  fontFamily: "Product sans",
});

const IncTestText: React.FC<any> = ({ group, canGoBack, onboarding }) => {
  const { handlePreviousTest } = useContext(IncTestContext);
  const { t } = useTranslation("common");

  const questionsNumbers = [32, 32, 10, 12];

  const labels = t("inc.labels", {
    returnObjects: true,
  }) as Array<string>;

  const instructions = t("inc.instructions", {
    returnObjects: true,
  }) as Array<string>;

  const [currentGroup, setCurrentGroup] = useState(1);

  useEffect(() => {
    if (group?.groupKey) {
      const key = group.groupKey.split("_")[0];
      setCurrentGroup(Number(key) ?? 1);
    }
  }, [group]);

  return (
    <SideA onboarding={onboarding}>
      {canGoBack && !onboarding && (
        <Box sx={{ position: "absolute", top: "20px", left: "20px" }}>
          <IconButton
            onClick={handlePreviousTest}
            sx={{ background: "#c4cdd57a" }}
          >
            <WestIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>
      )}
      <Box
        sx={{
          height: "calc(100% - 100px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ color: "white", textAlign: "center" }}>
          <Box
            display="flex"
            justifyContent="center"
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            <img
              style={{
                background: "white",
                padding: "10px",
                borderRadius: "50%",
                width: "55px",
                height: "55px",
              }}
              src="/static/icons/inc_logo.svg"
              alt="inc logo"
            />
          </Box>
          <Box>
            <Typography
              variant="h1"
              sx={{
                lineHeight: { xs: 1.1, md: 80 / 32 },
                mt: { xs: 2, md: 0 },
                mb: { xs: 2, md: 0 },
              }}
            >
              {t("tests.inc")}
            </Typography>
            <Box display="flex" width="100%" justifyContent="center">
              <Box display="flex" alignItems="center">
                <TimerIcon sx={{ mr: 1 }} />
                <Typography>
                  {t("inc.required_time")}{" "}
                  {currentGroup === 1
                    ? 12
                    : currentGroup === 2
                    ? 6
                    : currentGroup === 3
                    ? 6
                    : currentGroup === 4 && 6}{" "}
                  minutes
                </Typography>
              </Box>
            </Box>

            <FontTypography variant="h1" sx={{ mt: { xs: 2, sm: 8 } }}>
              {t("inc.part")} {currentGroup}/4
            </FontTypography>

            <FontTypography
              variant="h1"
              sx={{ fontWeight: "100", lineHeight: "45px" }}
            >
              {labels[currentGroup - 1]}
            </FontTypography>
            <Typography variant="h5" sx={{ fontWeight: "100", mt: 1 }}>
              {questionsNumbers[currentGroup - 1]} questions
            </Typography>

            <Typography
              sx={{ mt: 6, mb: { xs: 4, md: 0 } }}
              dangerouslySetInnerHTML={{
                __html: instructions[currentGroup - 1],
              }}
            />
          </Box>
        </Box>
      </Box>
    </SideA>
  );
};

export default IncTestText;
