import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  IconButton,
  Modal,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Close, HighlightOff } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import { ProfileContext } from "../../contexts/ProfileContext";
import peopleAPI from "../../_api/_people";
import StyledButton from "../styled/StyledButton";
import { modalStyle } from "../../_utils/modalStyle";
import { toast } from "react-toastify";

const DeleteButton = styled(StyledButton)(({ theme }: any) => ({
  color: theme.palette.error.main,
  backgroundColor: theme.palette.error[500_16],
  border: "none",
  "&:hover": {
    backgroundColor: theme.palette.error[500_24],
  },
}));

const Icon = styled(Box)(({ theme }: any) => ({
  borderRadius: "50%",
  margin: "auto",
  marginBottom: "11px",
  width: 55,
  height: 55,
  background: theme.palette.primary[500_8],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const DeleteIcon = styled(Close)({
  fontSize: "16px",
});

const HobbiesModal: React.FC<{ open: boolean; close: () => void }> = ({
  open,
  close,
}) => {
  const { t } = useTranslation("common");
  const [value, setValue] = useState("");
  const [hobbies, setHobbies] = useState<any>([]);
  const context = useContext(ProfileContext);
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setHobbies([]);
    setValue("");
  }, [open]);

  const save = async () => {
    setLoading(true);
    const hobbiesArray = context?.peopleData?.hobbies || [];
    const all = [...hobbiesArray, ...hobbies];
    try {
      const { data } = await peopleAPI.put(tokenData?.person_id, {
        hobbies: all,
      });

      context.setPeopleData({ ...context.peopleData, hobbies: data.hobbies });
      setLoading(false);
      toast.success(t("success.hobby_added"));
      close();
    } catch (err) {
      console.log("ERROR", err);
      setLoading(false);
      toast.error(t("errors.error_append"));
    }
  };

  const handleAdd = () => {
    setHobbies([...hobbies, value]);
    setValue("");
  };

  const removeFromList = (i: number) => {
    const arr = [...hobbies];
    arr.splice(i, 1);
    setHobbies(arr);
  };

  const handleKeyDown = (e: any) => {
    e.keyCode === 13 &&
      e.target.value.replace(/\s/g, "").length > 0 &&
      handleAdd();
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", sm: "80%", md: "600px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            paddingTop: 3,
          }}
        >
          <Box sx={{ ml: "24px", flexGrow: 1 }}>
            <Icon>
              <img
                src={`/static/icons/happy.svg`}
                alt={`experience icon`}
                color="primary"
              />
            </Icon>
            <Typography
              variant="h5"
              sx={{ ml: "24px", textAlign: "center", flexGrow: 1 }}
            >
              {t("profilepage.hobbies_section.add_plural")}
            </Typography>
          </Box>

          <IconButton aria-label="close" onClick={close} sx={{ mr: 1, mt: -2 }}>
            <HighlightOff />
          </IconButton>
        </Box>
        <CardContent>
          <TextField
            id="filled-disabled"
            sx={{ width: "100%" }}
            onKeyDown={handleKeyDown}
            label={t("profilepage.hobbies_section.leisure")}
            value={value}
            onChange={(e: any) => setValue(e.target.value)}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <Button
                  onClick={handleAdd}
                  sx={{
                    visibility:
                      value.replace(/\s/g, "").length > 0
                        ? "visible"
                        : "hidden",
                  }}
                >
                  <Typography>{t("btn.add")}</Typography>
                </Button>
              ),
            }}
          />
          {/* LIST */}

          <Box>
            {hobbies.map((hobbie: string, i: number) => (
              <Box
                sx={{ display: "flex", alignItems: "center", pt: 1 }}
                key={hobbie + i}
              >
                <Typography>{hobbie}</Typography>

                <DeleteButton
                  sx={{ ml: "auto" }}
                  onClick={() => removeFromList(i)}
                >
                  <DeleteIcon />
                </DeleteButton>
              </Box>
            ))}
          </Box>
        </CardContent>
        <CardActions
          sx={{
            pl: 3,
            pr: 3,
            pb: 2,
            mb: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={save}
            disabled={hobbies.length <= 0 || loading}
            variant="contained"
            size="large"
            sx={{ fontSize: "14px" }}
          >
            {t("btn.save")}
            {loading && <CircularProgress size={14} sx={{ ml: 1 }} />}
          </Button>
          <Button
            onClick={close}
            disabled={loading}
            size="large"
            sx={{ fontSize: "14px" }}
          >
            {t("btn.cancel")}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default HobbiesModal;
