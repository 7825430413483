import { Collapse, Zoom } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const AreaChart: React.FC<{
  data?: any;
  labels?: string[];
}> = ({ data, labels }) => {
  const theme: any = useTheme();
  const { t } = useTranslation("common");
  const chartParams: any = {
    options: {
      chart: {
        height: 350,
        type: "area",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: [
          theme.palette.tests.b5,
          theme.palette.tests.inc,
          theme.palette.tests.mood,
        ],
      },
      stroke: {
        curve: "smooth",
        colors: [
          theme.palette.tests.b5,
          theme.palette.tests.inc,
          theme.palette.tests.mood,
        ],
      },
      legend: {
        markers: {
          fillColors: [
            theme.palette.tests.b5,
            theme.palette.tests.inc,
            theme.palette.tests.mood,
          ],
        },
      },
      xaxis: {
        categories: labels,
        labels: { style: { fontFamily: "Product sans" } },
      },
      tooltip: { enabled: false },
    },
  };

  const chart = {
    ...chartParams,
    options: {
      ...chartParams.options,
    },
    series: [
      { name: t("tests.b5"), data: data?.map((el: any) => el?.b5) },
      { name: t("tests.inc"), data: data?.map((el: any) => el?.inc) },
      { name: t("tests.mood"), data: data?.map((el: any) => el?.mood) },
    ],
  };

  return (
    <Collapse in={true} timeout="auto" unmountOnExit>
      <Zoom in={true}>
        <Box>
          <ReactApexChart
            series={chart.series}
            options={chart.options}
            chart={chart.chart}
            height={200}
            type="area"
          />
        </Box>
      </Zoom>
    </Collapse>
  );
};

export default AreaChart;
