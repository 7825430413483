import { Button, Typography, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";

const ConnectImage = styled("img")({
  maxWidth: "250px",
  height: "auto",
  cursor: "pointer",
  transition: "all 0.1s ease-in-out",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

const LinkCtn = styled(Button)`
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #4c6fff;
  color: inherit;
  width: 100%;
  height: 100%;
`;

const PEConnectButton: React.FC<{ link?: boolean }> = ({ link }) => {
  const home = useSelector((state: any) => state.home, shallowEqual);
  const url = home?.check_saml?.url;
  const url2 = home?.check_saml?.url2;
  const { t } = useTranslation("common");

  const handlePeConnect = () => {
    window.location = url;
  };

  const handleLinkPe = () => {
    window.location = url2;
  };

  return (
    <>
      {url && !link && (
        <div className="ft" onClick={handlePeConnect}>
          <FTButton />
        </div>
      )}

      {url2 && link && (
        <LinkCtn className="ft" onClick={handleLinkPe} variant="outlined">
          <Typography fontWeight="bold">{t("pe.retrieve")}</Typography>
          <FTButton />
        </LinkCtn>
      )}
    </>
  );
};

export default PEConnectButton;

const FTButton = () => {
  return (
    <button
      type="button"
      className="btn btn-transverse light btn-lg"
      id="ft-btn"
    >
      <span id="ft-logo">
        <svg
          enable-background="new 0 0 195.2 219.2"
          viewBox="0 0 195.2 219.2"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m195.2 155.4c0 10.1-8.2 18.4-18.4 18.4-10.1 0-18.4-8.2-18.4-18.4 0-10.1 8.2-18.4 18.4-18.4 10.1.1 18.4 8.3 18.4 18.4"
            fill="#283276"
          ></path>
          <path
            d="m74.1 86.9c0 10.1-8.2 18.4-18.4 18.4s-18.4-8.2-18.4-18.4c0-10.1 8.2-18.4 18.4-18.4s18.4 8.2 18.4 18.4"
            fill="#F29FC5"
          ></path>
          <path
            d="m160.4 135.3c0 10.1-8.2 18.4-18.4 18.4-10.1 0-18.4-8.2-18.4-18.4 0-10.1 8.2-18.4 18.4-18.4 10.2.1 18.4 8.3 18.4 18.4"
            fill="#008ECF"
          ></path>
          <path
            d="m116 157.5c0 10.1-8.2 18.4-18.4 18.4-10.1 0-18.4-8.2-18.4-18.4 0-10.1 8.2-18.4 18.4-18.4 10.2.1 18.4 8.3 18.4 18.4"
            fill="#F29FC5"
          ></path>
          <path
            d="m116 200.8c0 10.1-8.2 18.4-18.4 18.4-10.1 0-18.4-8.2-18.4-18.4 0-10.1 8.2-18.4 18.4-18.4 10.2.1 18.4 8.3 18.4 18.4m79.2-136.8c0 10.1-8.2 18.4-18.4 18.4-10.1 0-18.4-8.2-18.4-18.4 0-10.1 8.2-18.4 18.4-18.4 10.1.1 18.4 8.3 18.4 18.4m-34.8 22.9c0 10.1-8.2 18.4-18.4 18.4-10.1 0-18.4-8.2-18.4-18.4 0-10.1 8.2-18.4 18.4-18.4s18.4 8.2 18.4 18.4m-89 50.1c0 10.1-8.2 18.4-18.4 18.4s-18.4-8.2-18.4-18.4c0-10.1 8.2-18.4 18.4-18.4s18.4 8.2 18.4 18.4m-35-77c2.2 9.9-4 19.7-13.9 21.9s-19.7-4.1-21.9-13.9c-2.2-9.9 4-19.7 13.9-21.9s19.7 4 21.9 13.9"
            fill="#283276"
          ></path>
          <path
            d="m34.6 99.7c-.8 5.7-6.1 9.7-11.8 8.9s-9.7-6.1-8.9-11.8 6.1-9.7 11.8-8.9 9.7 6.1 8.9 11.8m105.3 75.7c-.9 6.6-7 11.1-13.6 10.2s-11.1-7-10.2-13.6 7-11.1 13.6-10.2 11.1 7 10.2 13.6m35.6-76.1c-.6 4-4.2 6.7-8.2 6.2-4-.6-6.7-4.2-6.2-8.2.6-4 4.2-6.7 8.2-6.2 4 .6 6.8 4.2 6.2 8.2m-71.5-34.1c-.8 5.7-6.1 9.7-11.8 8.9s-9.7-6.1-8.9-11.8 6.1-9.7 11.8-8.9 9.7 6.1 8.9 11.8"
            fill="#E1000F"
          ></path>
          <path
            d="m134.1 51.6c-.8 5.7-6.1 9.7-11.8 8.9s-9.7-6.1-8.9-11.8 6.1-9.7 11.8-8.9 9.7 6.1 8.9 11.8"
            fill="#F29FC5"
          ></path>
          <path
            d="m66.1 166.5c-.8 5.7-6.1 9.7-11.8 8.9s-9.7-6.1-8.9-11.8 6.1-9.7 11.8-8.9c5.8.8 9.7 6.1 8.9 11.8m102.8-46.9c-.8 5.7-6.1 9.7-11.8 8.9s-9.7-6.1-8.9-11.8 6.1-9.7 11.8-8.9c5.7.7 9.7 6 8.9 11.8"
            fill="#FFE000"
          ></path>
          <path
            d="m156.9 168.1c3.1 4.9 1.6 11.3-3.3 14.4s-11.3 1.6-14.4-3.3-1.6-11.3 3.3-14.4c4.9-3 11.3-1.6 14.4 3.3"
            fill="#283276"
          ></path>
          <path
            d="m77.1 45.4c-.8 5.7-6.1 9.7-11.8 8.9s-9.7-6.1-8.9-11.8 6.1-9.7 11.8-8.9c5.8.8 9.7 6.1 8.9 11.8"
            fill="#FFE000"
          ></path>
          <path
            d="m116.9 19.3c0 10.6-8.6 19.3-19.3 19.3-10.6 0-19.3-8.6-19.3-19.3-0-10.6 8.6-19.3 19.3-19.3s19.3 8.6 19.3 19.3m-80.1 136c0 10.1-8.2 18.4-18.4 18.4s-18.4-8.2-18.4-18.4c0-10.1 8.2-18.4 18.4-18.4s18.4 8.3 18.4 18.4m90.4-49.4c0 17.7-14.3 32-32 32s-32-14.3-32-32 14.3-32 32-32c17.7-.1 32 14.3 32 32"
            fill="#008ECF"
          ></path>
        </svg>{" "}
      </span>
      <span className="btn-content">Se connecter avec France Travail</span>
    </button>
  );
};
