import axios from "axios";
import { logout } from "./redux/actions/authActions";
import store from "./redux/store";
import { deleteCookie } from "./Utils";
import rolesKeys from "./_utils/rolesList";

export const setAxios = () => {
  /** axios configuration */
  const origin = window.location.origin;
  const env = process.env.REACT_APP_ENV;

  let baseUrl = process.env.REACT_APP_BASEURL;

  if (
    !origin.includes("monkey-tie") &&
    process.env.REACT_APP_GOM !== "true" &&
    !origin.includes("localhost")
  ) {
    const split = origin.split(".");
    split[0] = `https://mtback-${env}`;
    baseUrl = split.join(".") + "/api/";
  }

  axios.defaults.baseURL = baseUrl;
  axios.defaults.withCredentials = true;
  axios.defaults.headers["Accept"] = "application/ld+json";
  axios.defaults.headers["Content-Type"] = "application/ld+json";
  axios.defaults.headers["content-language"] =
    localStorage.getItem("lng") || "fr";
  axios.defaults.headers["X-Context"] = localStorage.getItem("context") || " ";

  // axios.interceptors.request.use(function (config) {
  //   if (config.method === "post") {
  //     try {
  //       const body = config.data;
  //       const obj: Partial<typeof body> = {};
  //       for (const key in body) {
  //         if (body[key] !== null && body[key] !== undefined) {
  //           obj[key] = body[key];
  //         }
  //       }
  //       config.data = obj;
  //     } catch (err) {
  //       return config;
  //     }
  //   }

  //   return config;
  // });

  axios.interceptors.response.use(
    function (response: any) {
      if (response?.headers?.["x-context"]) {
        if (
          rolesKeys[`ROLE_${response?.headers?.["x-context"].toUpperCase()}`]
        ) {
          if (!localStorage.getItem("context")) {
            localStorage.setItem("context", response.headers["x-context"]);
          }
          window.dispatchEvent(new Event("storage"));
          axios.defaults.headers["X-Context"] = localStorage.getItem("context");
        } else {
          localStorage.setItem("change_context", "true");
          window.dispatchEvent(new Event("storage"));
        }
      }

      return response;
    },
    function (error) {
      // response error
      if (
        error?.response?.status === 401 &&
        !window.location.pathname.includes("login") &&
        !window.location.pathname.includes("account/params") &&
        !window.location.pathname.includes("sign-up")
      ) {
        deleteCookie();
        removeAxiosContext();
        // setAxiosAuthHeaderToken("");
        console.log("Disconnected");

        sessionStorage.setItem("disconnected-path", window.location.pathname);

        // alert disconnected

        sessionStorage.setItem("disconnected", "401");
        store.dispatch(logout());
      }
      // console.log("Intercepted error", error);
      return Promise.reject(error?.response ?? error); // transform response.response -> response
    }
  );
};

export const setAxiosAuthHeaderToken = (token: string) => {
  // axios.defaults.headers["Authorization"] =
  //   token?.length > 0 && token !== "null" ? `Bearer ${token}` : "";
};

export const removeAxiosContext = () => {
  axios.defaults.headers["X-Context"] = " ";
};
