import {
  Box,
  Card,
  CardContent,
  Collapse,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useFetch from "../../../hooks/useFetch";
import palette from "../../../theme/palette";
import peopleAPI from "../../../_api/_people";
import ProfileProgressionSkeleton from "../../skeletons/collab/ProfileProgressionSkeleton";
import TitleWithShowAll from "../../TitleWithShowAll";
import { useSelector } from "react-redux";

const ProfileProgression: React.FC<{
  personId: string;
  profile?: boolean;
  completeness?: any;
}> = ({ personId, profile, completeness }) => {
  const navigate = useNavigate();
  const context = useSelector((state: any) => state.auth.context);
  const { t } = useTranslation("common");

  const chartParams: any = {
    options: {
      stroke: {
        lineCap: "round",
      },
      legend: {
        show: false,
      },
      fill: { colors: ["#82C91E", "#4C6EF5"] },

      colors: ["#82C91E", "#4C6EF5"],
      plotOptions: {
        radialBar: {
          hollow: {
            size: "60%",
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              formatter: (e: any) =>
                `${e === "Total" ? t("big5.test.completeness") : e}`,
              offsetY: 30,
              color: "secondary.light",
            },
            value: {
              show: true,
              fontSize: "30px",
              offsetY: -10,
              color: palette.secondary.light,
              fontFamily: "Product sans",
            },
            total: {
              show: true,
              color: "secondary.light",
              fontSize: "12px",
              fontFamily: "Product sans",
              fontWeight: "regular",
              formatter: function (w: any) {
                return `${
                  completeness?.completenessOfProfile?.score ??
                  data?.completenessOfProfile?.score
                } %`;
              },
            },
          },
        },
      },
    },
  };

  const { data, loading } = useFetch(
    peopleAPI.getById(`${personId}/completeness`),
    completeness
  );

  const chart = {
    ...chartParams,
    options: {
      ...chartParams.options,
      labels: [t("profile"), t("tests.label")],
    },
    series: [
      completeness?.completenessOfProfile?.pProfile ??
        data?.completenessOfProfile?.pProfile,
      completeness?.completenessOfProfile?.pTest ??
        data?.completenessOfProfile?.pTest,
    ],
  };

  return loading ? (
    <ProfileProgressionSkeleton />
  ) : (context !== "recruiter" && data) ||
    (context === "recruiter" && completeness) ? (
    <Collapse in={true} timeout="auto" unmountOnExit>
      <Box className="profileprogression">
        {!profile && (
          <Box sx={{ mb: 1 }}>
            <TitleWithShowAll
              buttonAction={() => {
                navigate("/app/profile");
              }}
              title={t("completeness.title")}
            />
          </Box>
        )}
        <Card>
          <CardContent>
            {profile && (
              <Typography fontWeight="bold" variant="body1">
                {t("completeness.title")}
              </Typography>
            )}
            <ReactApexChart
              series={chart.series}
              options={chart.options}
              height={250}
              type="radialBar"
            />
            <Divider />
            <Box
              display="flex"
              justifyContent="space-around"
              marginTop={2}
              flexWrap="wrap"
            >
              <Box display="flex" alignItems="center">
                <Box
                  sx={{
                    background: "#82C91E",
                    width: "15px",
                    height: "15px",
                    borderRadius: "50px",
                    mr: 1,
                  }}
                />
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("profile")}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center">
                <Box
                  sx={{
                    background: "#4C6EF5",
                    width: "15px",
                    height: "15px",
                    borderRadius: "50px",
                    mr: 1,
                  }}
                />
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("tests.label")}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Collapse>
  ) : (
    <></>
  );
};
export default ProfileProgression;
