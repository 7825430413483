import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import HomeIcon from "../../icons/menu/home";
import ProfileIcon from "../../icons/menu/profile";
import TestIcon from "../../icons/menu/test";
import NavigationRenderer from "../NavigationRenderer";

const ThirdPartyContextMenu: React.FC<{
  handleLinkClick: any;
}> = ({ handleLinkClick }: any) => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  const location = useLocation();
  const links = [
    {
      icon: (
        <HomeIcon
          active={location.pathname.includes("app/home")}
          context={context}
          size={25}
        />
      ),
      label: t("home"),
      path: "/app/home",
      active: location.pathname.includes("app/home"),
    },
    {
      label: t("advisor.folder.label"),
      icon: (
        <TestIcon
          active={location.pathname.includes("folder")}
          context={context}
          size={25}
        />
      ),
      path: "/app/folder/list",
      active: location.pathname.includes("folder"),
    },
    {
      label: t("profilepage.profiles"),
      icon: (
        <ProfileIcon
          active={location.pathname.includes("profiles")}
          context={context}
          size={25}
        />
      ),
      path: "/app/profiles",
      active: location.pathname.includes("profiles"),
    },
    // menu?.works && {
    //   icon: (
    //     <WorkIcon
    //       active={location.pathname.includes("app/work")}
    //       context={context}
    //       size={25}
    //     />
    //   ),
    //   label: t("work_suggest.work_label"),
    //   path: "/app/work/list",
    //   active: location.pathname.includes("app/work"),
    // },
    // menu?.trainings && {
    //   icon: (
    //     <TrainingIcon
    //       active={location.pathname.includes("trainings/list")}
    //       context={context}
    //       size={25}
    //     />
    //   ),
    //   label: t("trainings.page"),
    //   path: "/app/trainings/list",
    //   active: location.pathname.includes("trainings/list"),
    // },
    // menu?.jobs && {
    //   icon: (
    //     <JobSuggestIcon
    //       active={location.pathname.includes("job/suggest")}
    //       context={context}
    //       size={25}
    //     />
    //   ),
    //   label: t("job_suggest.title"),
    //   path: "/app/job/suggest",
    //   active: location.pathname.includes("job/suggest"),
    // },
    // menu?.resources && {
    //   icon: <ResourcesMenuIcon />,
    //   label: t("resources.title"),
    //   path: "/app/resources",
    //   active: location.pathname.includes("resources"),
    // },
  ].filter((el: any) => el && el !== false);

  return <NavigationRenderer links={links} handleLinkClick={handleLinkClick} />;
};

export default ThirdPartyContextMenu;
