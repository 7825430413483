import FileSaver from "file-saver";
import { BehaviorSubject, Observable, tap } from "rxjs";
import peopleAPI from "../_api/_people";
import { getNumericDate } from "../_utils/getNumericDate";
import http from "./http/http";

// types
type State = {
  filters: Filters;
  page: number;
  search: string;
};

type Filters = {
  location: any[];
  project: boolean;
  rank: string;
  skill: any[];
  works: any[];
  service: any[];
  inProgress: boolean;
  followed: boolean;
};

// end types

export const baseFilters: Filters = {
  location: [],
  project: false,
  rank: "",
  skill: [],
  works: [],
  service: [],
  inProgress: false,
  followed: false,
};

const initialState: State = {
  filters: { ...baseFilters },
  page: 1,
  search: "",
};

class employeeService {
  static employees = [];
  static _employees = new BehaviorSubject(this.employees);
  static params = JSON.parse(JSON.stringify(initialState));
  static _params = new BehaviorSubject(this.params);

  static get(): Observable<any> {
    return http
      .get(
        peopleAPI.get({
          ...this.params,
          filters: JSON.stringify(this.params.filters),
        })
      )
      .pipe(
        tap({
          next: (res) => {
            this.setEmployees(res.data["hydra:member"]);
          },
          error: () => {},
        })
      );
  }

  static setPage(page: number) {
    this.setParams({ page });
  }

  static setParams(newParams: any) {
    this.params = { ...this.params, ...newParams };
    this._params.next(this.params);
  }

  static setFilters(filters: any) {
    this.setParams({
      ...this.params,
      filters: { ...this.params.filters, ...filters },
    });
  }

  static setEmployees(list: any) {
    this.employees = list;
    this._employees.next(this.employees);
  }

  static reset() {
    this.setParams(JSON.parse(JSON.stringify(initialState)));
    this.setEmployees([]);
  }

  static export() {
    return http
      .get(`people/export-excel`, {
        params: {
          filters: JSON.stringify(this.params.filters),
          search: this.params.search,
        },
        responseType: "blob",
        headers: { "Content-Type": "blob" },
      })
      .pipe(
        tap({
          next: (res) => {
            const today = getNumericDate(new Date()).replaceAll("/", "-");

            console.log(today);
            const blob2 = new Blob(
              [
                res.data,
                {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                },
              ],
              {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              }
            );
            FileSaver.saveAs(blob2, `Liste_collaborateurs_${today}.csv`);
          },
          error: () => {},
        })
      );
  }
}

export default employeeService;
