import { Collapse, Zoom } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const SingleColumnChart: React.FC<{
  data?: number[];
  labels?: string[];
  height?: number;
}> = ({ data, labels, height = 200 }) => {
  const { t } = useTranslation("common");
  const theme: any = useTheme();
  const chartParams: any = {
    options: {
      grid: {
        strokeDashArray: 3,
        position: "back",
        borderColor: "#f2f3f5",
      },
      colors: [theme.palette.primary.main],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 5,
          columnWidth: "40%",
        },
      },
      chart: {
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        categories: labels,
        labels: { style: { fontFamily: "Product sans" } },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        fixed: {
          enabled: true,
          position: "topRight",
          offsetX: 0,
          offsetY: 20,
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          return `<div style="padding: 6px;">
          <span>Nombre de connexions :  <b>${series[seriesIndex][dataPointIndex]}</b></span></div>`;
        },
      },
    },
  };

  const chart = {
    ...chartParams,
    options: {
      ...chartParams.options,
    },
    series: [
      {
        name: t("stats.connection"),
        data: data,
      },
    ],
  };
  return (
    <Collapse in={true} timeout="auto" unmountOnExit>
      <Zoom in={true}>
        <Box>
          <ReactApexChart
            series={chart.series}
            options={chart.options}
            chart={chart.chart}
            height={height}
            type="bar"
          />
        </Box>
      </Zoom>
    </Collapse>
  );
};

export default SingleColumnChart;
