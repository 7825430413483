import React from "react";
import { useTheme } from "@mui/styles";
import { Box, Typography, Tooltip, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const BoxText: any = styled(Box, {
  shouldForwardProp: (props) => props !== "type",
})(({ theme, type }: any) => ({
  borderRadius: "7px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor:
    type === "skills"
      ? theme.palette.warning[100]
      : theme.palette.tests[`${type}_light`],
}));

const CampaignCandidatesScores = ({
  scores,
  justifyContent,
  matchingPage,
  campaign,
}: any) => {
  const theme: any = useTheme();
  const { t }: any = useTranslation("common");

  return (
    <Box
      display="flex"
      alignItems="center"
      flexWrap={matchingPage ? "wrap" : "nowrap"}
      justifyContent={justifyContent ?? "start"}
      sx={{
        width: "auto",
        mb: { xs: 1.5, md: 0 },
      }}
    >
      {campaign?.expectedData?.skills && (
        <Tooltip title={t("tests.skills")}>
          <BoxText
            type="skills"
            sx={{
              opacity: scores?.skills >= 0 ? 1 : 0.4,
              p: 1,
              m: matchingPage ? 1 : 0,
              ml: matchingPage ? 1 : 0,
              width: matchingPage ? "125px" : "65px",
              height: matchingPage ? "45px" : "27px",
            }}
          >
            <img
              alt="skills"
              src="/static/icons/skills_abilities_logo.svg"
              width={matchingPage ? "32" : "20"}
            />
            <Typography
              variant="h3"
              fontWeight="regular"
              sx={{
                color: theme.palette.tests.skills,
                ml: matchingPage ? 1 : 0.5,
                fontSize: matchingPage ? "33px" : "18px",
              }}
            >
              {scores?.skills?.toFixed(0) ?? 0}%
            </Typography>
          </BoxText>
        </Tooltip>
      )}
      {campaign?.expectedData?.personality && (
        <Tooltip title={t("tests.b5")}>
          <BoxText
            type="b5"
            sx={{
              p: 1,
              opacity: scores?.personality !== null ? 1 : 0.4,
              m: matchingPage ? 1 : 0,
              ml: matchingPage ? 1 : 2,
              width: matchingPage ? "125px" : "65px",
              height: matchingPage ? "45px" : "27px",
            }}
          >
            <img
              alt="big 5"
              src="/static/icons/b5_logo.svg"
              width={matchingPage ? "28" : "16"}
            />
            <Typography
              variant="h3"
              fontWeight="regular"
              sx={{
                color: theme.palette.tests.b5,
                ml: matchingPage ? 1 : 0.5,
                fontSize: matchingPage ? "33px" : "18px",
              }}
            >
              {scores?.personality?.toFixed(0) ?? 0}%
            </Typography>
          </BoxText>
        </Tooltip>
      )}
      {campaign?.expectedData?.valors && (
        <Tooltip title={t("tests.cf")}>
          <BoxText
            type="cf"
            sx={{
              p: 1,
              opacity: scores?.motivations !== null ? 1 : 0.4,
              m: matchingPage ? 1 : 0,
              ml: matchingPage ? 1 : 2,
              width: matchingPage ? "125px" : "65px",
              height: matchingPage ? "45px" : "27px",
            }}
          >
            <img
              alt="culture fit"
              src="/static/icons/cf_logo.svg"
              width={matchingPage ? "30" : "18"}
            />
            <Typography
              variant="h3"
              fontWeight="regular"
              sx={{
                color: theme.palette.tests.cf,
                ml: matchingPage ? 1 : 0.5,
                fontSize: matchingPage ? "33px" : "18px",
              }}
            >
              {scores?.motivations?.toFixed(0) ?? 0}%
            </Typography>
          </BoxText>
        </Tooltip>
      )}
      {(campaign?.expectedData?.inc || campaign?.expectedData?.gamified) && (
        <Tooltip title={t("tests.inc")}>
          <BoxText
            type="inc"
            sx={{
              opacity: scores?.dynamics !== null ? 1 : 0.4,
              p: 1,
              m: matchingPage ? 1 : 0,
              ml: matchingPage ? 1 : 2,
              width: matchingPage ? "125px" : "65px",
              height: matchingPage ? "45px" : "27px",
            }}
          >
            <img
              alt="inc"
              src="/static/icons/inc_logo.svg"
              width={matchingPage ? "30" : "18"}
            />
            <Typography
              variant="h3"
              fontWeight="regular"
              sx={{
                color: theme.palette.tests.inc,
                ml: matchingPage ? 1 : 0.5,
                fontSize: matchingPage ? "33px" : "18px",
              }}
            >
              {scores?.dynamics?.toFixed(0) ?? 0}%
            </Typography>
          </BoxText>
        </Tooltip>
      )}
    </Box>
  );
};

export default CampaignCandidatesScores;
