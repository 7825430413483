import { useTheme } from "@mui/styles";
import React from "react";

const WorkIcon: React.FC<{
  active?: boolean;
  context?: string;
  size?: number;
}> = ({ active, context, size }) => {
  const theme: any = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? "20"}
      height={size ?? "20"}
      viewBox="0 0 13.477 13.473"
    >
      <path
        id="_006-suitcase"
        data-name="006-suitcase"
        d="M12.42,6.368V7.447a.526.526,0,1,0,1.052,0V4.238a2.108,2.108,0,0,0-2.105-2.105H9.1V1.446A1.448,1.448,0,0,0,7.656,0H5.816A1.448,1.448,0,0,0,4.37,1.446v.686H2.105A2.107,2.107,0,0,0,0,4.237v6.131a2.108,2.108,0,0,0,2.105,2.105H6.552a.526.526,0,0,0,0-1.053H2.105a1.054,1.054,0,0,1-1.052-1.053v-4H6.236v.79a.526.526,0,1,0,1.052,0v-.79Zm-7-4.922a.394.394,0,0,1,.394-.394h1.84a.394.394,0,0,1,.394.394v.686H5.423ZM1.053,4.238A1.054,1.054,0,0,1,2.105,3.185h9.263A1.054,1.054,0,0,1,12.42,4.238V5.315H1.053Zm12.27,8.337-1.212-1.212a2.37,2.37,0,1,0-.743.745l1.211,1.211a.526.526,0,0,0,.744-.744ZM8.789,10.1A1.316,1.316,0,1,1,10.1,11.42,1.317,1.317,0,0,1,8.789,10.1Zm0,0"
        fill={
          active && context === "recruiter"
            ? "#4C6FFF"
            : !active &&
              (context === "collab" ||
                context === "benef" ||
                context === "candidate")
            ? theme.palette.contextColors.collab.font
            : context === "recruiter"
            ? theme.palette.contextColors.recruiter.font
            : "#fff"
        }
      />
    </svg>
  );
};

export default WorkIcon;
