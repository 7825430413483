import { styled } from "@mui/material";
import { Typography } from "@mui/material";
import React from "react";
import { SkillRatingIcon } from "./Skill";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Legend: React.FC<any> = ({ category, workLabel }) => {
  const { context, isCollab } = useSelector((state: any) => state.auth);
  const { t } = useTranslation("common");

  return (
    <Root>
      <LegendBlock>
        <LegendIcon>
          <SkillRatingIcon style={{ backgroundColor: category.theme.button }} />
        </LegendIcon>

        <Typography
          variant="h5"
          sx={{
            fontSize: "18px!important",
            color: category.theme.font,
            marginLeft: "auto",
          }}
        >
          {t("skills.workflow.compare.left", {
            context: isCollab ? "collab" : context,
          })}
        </Typography>
      </LegendBlock>

      <LegendBlock
        className="right"
        style={{ marginRight: "auto", marginLeft: 0 }}
      >
        <LegendIcon>
          <SkillRatingIcon style={{ backgroundColor: "#3b3b3b" }} />
        </LegendIcon>
        <Typography
          variant="h5"
          sx={{
            fontSize: "18px!important",
            color: category.theme.font,
            marginRight: "auto",
          }}
        >
          {workLabel}
        </Typography>
      </LegendBlock>
    </Root>
  );
};

export default Legend;

const Root = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px 20px;
  grid-auto-rows: minmax(50px, auto);
  @media screen and (max-width: 1010px) {
    text-align: left !important;
    .titles {
      display: none;
    }
    display: flex;
    flex-wrap: wrap;
  }
`;
const LegendBlock = styled("div")`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  &.right {
    margin-left: auto;
  }
  @media screen and (max-width: 1010px) {
    margin-left: 0 !important;
    &.right {
      margin-left: 0px !important;
    }
  }
`;
const LegendIcon = styled("div")`
  display: none;
  @media screen and (max-width: 1010px) {
    display: inherit;
  }
`;
