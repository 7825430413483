import {
  Box,
  Card,
  CardContent,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { HighlightOff } from "@mui/icons-material";
import { modalStyle } from "../../_utils/modalStyle";
import FeedbackModalBody from "./FeedbackModalBody";
import axios from "axios";
import { useTranslation } from "react-i18next";
import FeedbackPeBody from "./FeedbackPeBody";
import { useSelector, shallowEqual } from "react-redux";
import { toast } from "react-toastify";

const FeedbackModal: React.FC<{
  open: boolean;
  close: () => void;
}> = ({ open, close }) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [openPeModal, setOpenPeModal] = useState(false);
  const tokenData = useSelector((state: any) => state.user, shallowEqual);

  const handleFeedback = async (values: any) => {
    setLoading(true);
    const obj = { ...values, context: window.location.pathname };
    values.mood > 3 && tokenData.peConnect ? setOpenPeModal(true) : close();
    try {
      await axios.post(`feedback`, obj);
      toast.success(t("feedback.alert_sent"));
      setLoading(false);
    } catch (err) {
      toast.error(t("errors.error_append"));
      setLoading(false);
    }
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "350px", sm: "445px" },
        }}
      >
        <Box sx={{ width: "100%", display: "flex" }}>
          <IconButton
            onClick={() => {
              setOpenPeModal(false);
              close();
            }}
            sx={{ ml: "auto", mt: 1, mr: 1 }}
            aria-label="close"
          >
            <HighlightOff />
          </IconButton>
        </Box>
        <CardContent sx={{ pt: 0, pl: { sm: 6 }, pr: { sm: 6 } }}>
          {openPeModal ? (
            <FeedbackPeBody />
          ) : (
            <>
              <Typography
                variant="h4"
                sx={{
                  fontSize: "24px",
                  fontFamily: "Product sans",
                }}
              >
                {t("feedback.text_1")}
              </Typography>
              <Typography variant="body1">{t("feedback.text_1A")}</Typography>
              <FeedbackModalBody
                close={close}
                loading={loading}
                submit={handleFeedback}
              />
            </>
          )}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default FeedbackModal;
