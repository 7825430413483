import { Box, Divider, Typography } from "@mui/material";
import React, { useContext } from "react";
import AdminDisplayConnect from "./display/AdminDisplayConnect";
import AdminDisplayOnboardingCollab from "./display/AdminDisplayOnboardingCollab";
import AdminDisplayCollabRoles from "./display/AdminDisplayCollabRoles";
import AdminDisplayHome from "./display/AdminDisplayHome";
import AdminDisplayProfile from "./display/AdminDisplayProfile";
import AdminDisplayProjects from "./display/AdminDisplayProjects";
import AdminDisplayJobsTarget from "./display/AdminDisplayJobsTarget";
import AdminDisplayJobs from "./display/AdminDisplayJobs";
import AdminDisplayCollabs from "./display/AdminDisplayCollabs";
import AdminDisplayRH from "./display/AdminDisplayRH";
import { AdminParamsContext } from "../../../contexts/AdminParamsContext";
import AdminSaveParamsButton from "../AdminSaveParamsButton";
import AdminDisplayTest from "./display/AdminDisplayTest";
import AdminDisplayPageCollab from "./display/AdminDisplayPageCollab";
import { useTranslation } from "react-i18next";
import AdminDisplayChat from "./display/AdminDisplayChat";
import AdminDisplayRecruiter from "./display/AdminDisplayRecruiter";
import AdminDisplayTrainings from "./display/AdminDisplayTrainings";

const AdminDisplayParams: React.FC<any> = () => {
  const { t } = useTranslation("common");
  const { parametersFetch, editParams } = useContext(AdminParamsContext);

  const params = parametersFetch?.data?.["hydra:member"]?.find(
    (el: any) => el.type === "DISPLAY_PARAMS"
  );

  const homeParam = parametersFetch?.data?.["hydra:member"]?.find(
    (el: any) => el.type === "home.param"
  );

  const handleChanges = (p: any, key: string) => {
    const cp = { ...params };
    cp.parameters[key] = p;

    editParams(cp, "DISPLAY_PARAMS");
  };

  const handleChangesHome = (p: any, key: string) => {
    const cp = { ...homeParam };
    cp.parameters.menu[key] = p;

    editParams(cp, "home.param");
  };

  const obj = {
    param: params?.parameters,
    handleChanges,
  };

  return (
    <div>
      {params && (
        <Box sx={{ position: "relative" }}>
          <AdminDisplayConnect
            param={obj.param.CREATE_ACCOUNT}
            handleChanges={(p: any) => handleChanges(p, "CREATE_ACCOUNT")}
          />
          <AdminDisplayChat
            param={obj.param.CHAT}
            handleChanges={(p: any) => handleChanges(p, "CHAT")}
          />
          {homeParam && (
            <AdminDisplayTrainings
              param={homeParam?.parameters?.menu?.trainings}
              handleChanges={(p: any) => handleChangesHome(p, "trainings")}
            />
          )}
          <Typography variant="h3" sx={{ mb: 2, mt: 3 }}>
            {t("roles.collab")}
          </Typography>

          <AdminDisplayOnboardingCollab
            param={obj.param.ONBOARDING}
            handleChanges={(p: any) => handleChanges(p, "ONBOARDING")}
          />

          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminDisplayCollabRoles
            param={obj.param.ROLE}
            handleChanges={(p: any) => handleChanges(p, "ROLE")}
          />

          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminDisplayHome
            param={obj.param.HOME}
            handleChanges={(p: any) => handleChanges(p, "HOME")}
          />

          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminDisplayProfile
            param={obj.param.PROFIL}
            handleChanges={(p: any) => handleChanges(p, "PROFIL")}
          />

          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminDisplayTest
            param={obj.param.TEST}
            handleChanges={(p: any) => handleChanges(p, "TEST")}
          />

          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminDisplayProjects
            param={obj.param.PROJECTS}
            handleChanges={(p: any) => handleChanges(p, "PROJECTS")}
          />

          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminDisplayJobsTarget
            param={obj.param.WORK_SUGGEST}
            handleChanges={(p: any) => handleChanges(p, "WORK_SUGGEST")}
          />

          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminDisplayJobs
            param={obj.param.JOB_SUGGEST}
            handleChanges={(p: any) => handleChanges(p, "JOB_SUGGEST")}
          />

          <Divider sx={{ mt: 3, mb: 3 }} />
          <Typography variant="h3" sx={{ mb: 2, mt: 3 }}>
            {t("roles.rh")} / {t("roles.manager")}
          </Typography>
          <AdminDisplayRH
            param={obj.param.RH_DISPLAY}
            handleChanges={(p: any) => handleChanges(p, "RH_DISPLAY")}
          />

          <Divider sx={{ mt: 3, mb: 3 }} />
          <Typography variant="h3" sx={{ mb: 2, mt: 3 }}>
            {t("roles.advisor")} / {t("roles.recruiter")}
          </Typography>
          <AdminDisplayRecruiter
            param={obj.param.RECRUITER_POOL}
            handleChanges={(p: any) => handleChanges(p, "RECRUITER_POOL")}
          />

          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminDisplayCollabs
            param={obj.param.RH_COLLAB}
            handleChanges={(p: any) => handleChanges(p, "RH_COLLAB")}
          />
          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminDisplayPageCollab
            param={obj.param.PAGE_COLLAB}
            handleChanges={(p: any) => handleChanges(p, "PAGE_COLLAB")}
          />
          <AdminSaveParamsButton
            type="DISPLAY_PARAMS"
            additionalType={homeParam ? "home.param" : ""}
          />
        </Box>
      )}
    </div>
  );
};

export default AdminDisplayParams;
