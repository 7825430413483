import {
  Modal,
  Card,
  CardContent,
  Box,
  IconButton,
  Button,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { modalStyle } from "../../../_utils/modalStyle";
import { useDispatch, useSelector } from "react-redux";
import { HighlightOff } from "@mui/icons-material";
import { setActive, setOpen } from "../../../redux/actions/ptwPopupActions";
import ReplyIcon from "@mui/icons-material/Reply";

type PtwPopupProps = {
  open: boolean;
};

const SButton = styled(Button)`
  background-color: #ff7074;
  color: white;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: normal;
  &:hover {
    background-color: #ff8286;
  }
`;

const SIcon = styled(ReplyIcon)`
  transform: scaleX(-1);
`;

const PtwPopup: React.FC<PtwPopupProps> = ({ open }) => {
  const dispatch = useDispatch();
  const close = () => {
    dispatch(setActive(false));
    dispatch(setOpen(false));
  };
  const link = useSelector((state: any) => state.home?.paramjson?.webappUrl);

  const redirect = () => {
    window.open(link, "_blank");
  };

  return (
    <Modal open={open} onClose={close}>
      <Card
        sx={{
          ...modalStyle,
          width: {
            xs: "95%",
            sm: "400px",
            md: "400px",
          },
          padding: 0,
        }}
      >
        <CardContent sx={{ pt: 0, textAlign: "center", padding: 0 }}>
          <Box
            sx={{
              backgroundImage: 'url("/static/images/ptw_popup.png")',
              backgroundPosition: "center",
              backgroundSize: "cover",
              height: "320px",
              width: "100%",
              position: "relative",
            }}
          >
            <IconButton
              aria-label="close modal"
              onClick={close}
              sx={{ position: "absolute", right: 0, top: 0, color: "white" }}
            >
              <HighlightOff />
            </IconButton>
          </Box>

          <Box padding={"16px"}>
            <Typography
              variant="h4"
              textAlign="center"
              sx={{ fontFamily: "Product sans", lineHeight: "26px" }}
            >
              Trouvez le métier qui correspond à vos softskills
            </Typography>
            <Typography textAlign="center" marginTop={2}>
              Grâce à Pass To Work, découvrez vos compétences comportementales
              et le métier dans lequel vous vous épanouirez vraiment.{" "}
            </Typography>

            <SButton sx={{ mt: 3 }} onClick={redirect}>
              C'est parti ! <SIcon />
            </SButton>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default PtwPopup;
