import { styled } from "@mui/material";
import {
  Typography,
  Box,
  Rating,
  ButtonBase,
  Button,
  Tooltip,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import axios from "axios";
import { toastError } from "../../../../_utils/toastError";
import { useState } from "react";
import { useSelector } from "react-redux";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

const Skill: React.FC<any> = ({
  activeColor,
  editMode,
  inDeleteList,
  addToDeleteList,
  skill,
  editSkill,
  work,
  canAdd,
  reload,
}) => {
  const activeIcon = (
    <SkillRatingIcon
      style={{
        backgroundColor: work
          ? "#363636"
          : inDeleteList
          ? "#969696"
          : activeColor,
      }}
    />
  );

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("common");

  const isCollab = useSelector((state: any) => state.auth.isCollab);

  const handleEdit = (level: number) => {
    editSkill(skill.id, level >= 1 ? level : skill.level);
  };

  const handleAdd = async () => {
    try {
      setLoading(true);
      await axios[skill.personSkillId ? "put" : "post"](
        `person_skills${skill.personSkillId ? `/${skill.personSkillId}` : ""}`,
        {
          skill: skill["@id"],
          level: `/api/skill_levels/${skill.levels.right}`,
        }
      );
      reload?.();
    } catch (err) {
      toastError();
    }
    setLoading(false);
  };

  return (
    <SkillRoot>
      {!work && (
        <Typography className={`skill-label ${inDeleteList ? "delete" : ""}`}>
          {skill.label}
        </Typography>
      )}

      <Box display="flex" alignItems="center">
        <SkillRating
          level={skill.level}
          editSkill={handleEdit}
          readOnly={!editMode || inDeleteList}
          activeIcon={activeIcon}
        />
        {editMode && addToDeleteList && (
          <>
            <DeleteButton onClick={addToDeleteList}>
              <CloseRoundedIcon />
            </DeleteButton>
          </>
        )}
        {isCollab && canAdd && skill?.levels?.left < skill?.levels.right && (
          <Button disabled={loading} onClick={handleAdd}>
            {t("btn.add")}
          </Button>
        )}
      </Box>
    </SkillRoot>
  );
};

export const SkillRatingIcon = styled("div")`
  width: 40px;
  height: 25px;
  border-radius: 8px;
  background-color: white;
  margin-right: 5px;
`;

const CustomRating = styled(Rating)`
  & .MuiRating-iconActive {
    transform: none !important;
  }
`;

const SkillRating: React.FC<any> = ({
  activeIcon,
  readOnly,
  editSkill,
  level,
}) => {
  return (
    <CustomRating
      name="simple-controlled"
      value={level}
      max={4}
      readOnly={readOnly}
      icon={activeIcon}
      emptyIcon={<SkillRatingIcon />}
      onChange={(event, newValue) => {
        editSkill(newValue);
      }}
    />
  );
};

const DeleteButton = styled(ButtonBase)`
  background-color: #849e9b;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50px;
`;

const SkillRoot = styled("div")`
  display: flex;
  align-items: center;
  .skill-label {
    font-size: 14px;
    max-width: 32ch;
    width: 23ch;
    margin-top: 10px;
    margin-right: 10px;
    &.delete {
      color: #969696;
    }
  }
  @media screen and (max-width: 1010px) {
    flex-direction: column !important;
    align-items: baseline !important;
    text-align: left !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;

export const Skill2: React.FC<any> = ({
  skill,
  activeColor,
  canDelete,
  canAdd,
  addToDeleteList,
  inDeleteList,
  editSkill,
  reload,
  showLabel = true,
  workflowState,
  editMode,
  personId,
  font,
  reverse = false,
  handleAdd = () => {},
  compare = false,
}) => {
  const invalid =
    skill.levels.validate === -1 &&
    (workflowState.status === 40 || workflowState.status === 50);

  const [valid, setValid] = useState(false);
  const { t } = useTranslation("common");
  const activeIcon = (
    <SkillRatingIcon
      style={{
        backgroundColor: inDeleteList || invalid ? "#969696" : activeColor,
      }}
    />
  );

  const { isCollab, context } = useSelector((state: any) => state.auth);

  const handleEdit = (level: number) => {
    setValid((c) => !c);

    if (
      (workflowState.status === 60 || workflowState.status === 30) &&
      inDeleteList
    ) {
      addToDeleteList();
    }

    editSkill(skill.id, level >= 1 ? level : skill.level);
  };

  const handleDelete = () => {
    addToDeleteList();

    if ((workflowState.status === 60 || workflowState.status === 30) && valid) {
      editSkill(skill.id, skill.level);
      setValid(false);
    }
  };

  const addHandler = () => {
    handleAdd?.(skill);
  };

  // const handleAdd = async () => {
  //   console.log(skill);

  //   return;

  //   try {
  //     setLoading(true);
  //     await axios.post(`person_skills`, {
  //       skill: skill["@id"],
  //       level: `/api/skill_levels/${skill.levels.right}`,
  //       ...(personId ? { person: `/api/people/${personId}` } : {}),
  //     });
  //     reload?.();
  //   } catch (err) {
  //     toastError();
  //   }
  //   setLoading(false);
  // };

  return (
    <SkillRoot
      style={{
        ...(compare
          ? {
              flexDirection: reverse ? "row-reverse" : "row",
              marginLeft: reverse ? 0 : "auto",
              marginRight: reverse ? "auto" : 0,
              textAlign: reverse ? "left" : "right",
            }
          : {}),
      }}
    >
      {showLabel && (
        <Typography
          sx={{ color: font }}
          className={`skill-label ${inDeleteList || invalid ? "delete" : ""}`}
        >
          {skill.label}
        </Typography>
      )}

      <Box
        display="flex"
        alignItems="center"
        sx={{
          flexDirection: reverse ? "row-reverse" : "row",
          marginRight: reverse ? 1 : 0,
        }}
      >
        <SkillRating
          level={skill.level}
          editSkill={handleEdit}
          readOnly={
            !editMode ||
            !workflowState.canUpdate ||
            inDeleteList ||
            (workflowState.status === 50 &&
              context === "manager" &&
              skill.levels?.validate !== -1)
          }
          activeIcon={activeIcon}
        />

        {(editMode ||
          workflowState.status === 30 ||
          workflowState.status === 60) &&
          canDelete &&
          addToDeleteList &&
          workflowState.status !== 10 &&
          isCollab &&
          (typeof skill.levels.validate === "number"
            ? skill.levels.validate === 0
            : true) && (
            <>
              <DeleteButton onClick={() => handleEdit(skill.levels.right)}>
                <CheckRoundedIcon sx={{ color: valid ? "#00FF00" : "#FFF" }} />
              </DeleteButton>
            </>
          )}

        {(editMode ||
          workflowState.status === 30 ||
          workflowState.status === 60) &&
          canDelete &&
          addToDeleteList &&
          isCollab &&
          (typeof skill.levels.validate === "number"
            ? skill.levels.validate === 0
            : true) && (
            <>
              <DeleteButton onClick={handleDelete}>
                <CloseRoundedIcon
                  sx={{ color: inDeleteList ? "#FF0000" : "#FFF" }}
                />
              </DeleteButton>
            </>
          )}

        {isCollab && canAdd && skill?.levels?.left < skill?.levels.right && (
          <Tooltip title={t("btn.add")}>
            <IconButton color="primary" onClick={addHandler}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        )}
        {isCollab && canAdd && skill?.levels?.left >= skill?.levels.right && (
          <IconButton color="primary" disabled={true}>
            <CheckRoundedIcon />
          </IconButton>
        )}
      </Box>
    </SkillRoot>
  );
};

export default Skill;
