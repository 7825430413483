import { Button, Fade, Slide, Stack, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import IncQuestionCursor from "./IncQuestionCursor";
import IncQuestionHandler from "./IncQuestionHandler";
import { IncTestContext } from "./IncTestContext";
import EastSharpIcon from "@mui/icons-material/EastSharp";
import useWindowDimensions from "../../../hooks/getWindowDimensions";
import IncTestEndText from "./IncTestEndText";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FinishLaterTestModal from "../../tests/FinishLaterTestModal";
import { useSelector, shallowEqual } from "react-redux";
import { Utils } from "../../../Utils";

const LetsGoContainer = styled(Box)({
  "&:hover": { transform: "scale(1.075)" },

  transition: "all 0.125s ease-in-out",
});

const LetsGoText = styled(Typography)({
  fontWeight: 500,
});

const IncTestPart: React.FC<any> = ({
  isIntro,
  mounted,
  onBoarding,
  nextStep,
  progress,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { handlePreviousTest, handleNextTest, question } =
    useContext(IncTestContext);

  const [questions, setQuestions] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [slidersQuestions, setSlidersQuestions] = useState<any>([]);
  const tokenData = useSelector((state: any) => state.user, shallowEqual);

  const { width } = useWindowDimensions();
  const isMobile = width <= 1400;

  const containerRef = React.useRef(null);

  const finishLater = () => {
    if (onBoarding) {
      const newState: any =
        Utils.getCookie(`${tokenData.person_id}_do_later_step`) || [];
      if (newState?.includes("motivations")) {
        newState?.push("motivations");
        Utils.setLongCookies(`${tokenData.person_id}_do_later_step`, newState);
      }
      const newStateComplete: any =
        Utils.getCookie(`${tokenData.person_id}_completed_step`) || [];
      if (!newStateComplete?.includes("mottivations")) {
        newStateComplete.push("mottivations");
        Utils.setLongCookies(
          `${tokenData.person_id}_completed_step`,
          newStateComplete
        );
      }
      nextStep();
    } else {
      navigate("/app/tests");
    }
  };

  useEffect(() => {
    if (question?.questions && mounted) {
      setQuestions(
        question.questions.filter(
          (el: any) => el.type !== "9" && el.type !== "7"
        )
      );
      setSlidersQuestions(
        question.questions.filter((el: any) => el.type === "7")
      );
    }
  }, [question, mounted]);

  return (
    <Box marginTop={4} flexDirection="column" display="flex" height="100%">
      {!isIntro && question?.questions?.length > 0 && (
        <Box display="flex">
          <Box
            ref={containerRef}
            sx={{ width: "100%", overflow: "hidden", minHeight: "70px" }}
          >
            <Slide direction="up" in={mounted} container={containerRef.current}>
              {question?.questions?.[0]?.code === "693" ? (
                <Box sx={{ maxWidth: "800px" }}>
                  <IncTestEndText />
                </Box>
              ) : (
                <Typography
                  sx={{
                    mb: 2,
                    marginLeft: "auto",
                    marginRight: "auto",
                    lineHeight: "33px",
                  }}
                  textAlign="center"
                  variant="h3"
                  dangerouslySetInnerHTML={{
                    __html:
                      `${Number(
                        question?.questions
                          ?.find((e: any) => e.type === "9")
                          ?.groupKey?.split("_")[1] - 1 ?? 1
                      )}. ` +
                      question?.questions?.find((e: any) => e.type === "9")
                        ?.label,
                  }}
                />
              )}
            </Slide>
          </Box>
        </Box>
      )}

      {isIntro && !onBoarding && (
        <Box
          display="flex"
          justifyContent="center"
          height="100%"
          alignItems="center"
        >
          <LetsGoContainer
            onClick={handleNextTest}
            sx={{
              display: "flex",
              alignItems: "center",
              lineHeight: "33px",
              cursor: "pointer",
            }}
          >
            <LetsGoText
              color="primary"
              variant="h1"
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "underline",
                fontFamily: "Product sans",
              }}
            >
              {t("inc.intro.label")}
            </LetsGoText>
            <EastSharpIcon fontSize="large" color="primary" sx={{ ml: 1 }} />
          </LetsGoContainer>
        </Box>
      )}

      {!isIntro && (
        <Fade
          in={true}
          unmountOnExit
          timeout={{
            appear: 1000,
            enter: 600,
            exit: 600,
          }}
        >
          <Box>
            <Box
              sx={{ opacity: mounted ? 1 : 0.3 }}
              display="flex"
              justifyContent="center"
            >
              <Stack marginTop={2} spacing={5}>
                {questions?.length > 0 &&
                  questions.map((el: any, i: number) => (
                    <IncQuestionHandler
                      key={el.code + String(i)}
                      question={el}
                      index={i}
                      mounted={mounted}
                    />
                  ))}
              </Stack>
            </Box>

            {slidersQuestions?.length > 0 && (
              <>
                <IncQuestionCursor
                  questions={slidersQuestions}
                  handleDoLater={() => setOpenModal(true)}
                  onBoarding={onBoarding}
                />
                {!isMobile && (
                  <Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      marginTop="auto"
                      flexWrap="wrap"
                      paddingTop={3}
                    >
                      {question?.questions?.[0]?.previousGroup &&
                        (onBoarding
                          ? question?.questions?.[0]?.previousGroup?.split(
                              "_"
                            )[1] !== "01"
                          : true) && (
                          <Button
                            onClick={handlePreviousTest}
                            sx={{
                              m: 1,
                              width: "240px",
                              height: "53px",
                              fontSize: "14px",
                              color: "#8F9BB3",
                            }}
                          >
                            {t("btn.prev")}
                          </Button>
                        )}
                      <Button
                        sx={{
                          width: "240px",
                          height: "53px",
                          fontSize: "14px",
                          m: 1,
                        }}
                        variant="contained"
                        onClick={handleNextTest}
                      >
                        {t("btn.next_quest")}
                      </Button>
                    </Box>
                    <Box display="flex" justifyContent="center" sx={{ m: 1 }}>
                      <Button
                        sx={{
                          width: "240px",
                          height: "53px",
                          fontSize: "14px",
                        }}
                        variant="outlined"
                        color="secondary"
                        onClick={() => setOpenModal(true)}
                      >
                        {t("tests.modal.do_later")}
                      </Button>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Fade>
      )}
      {!isIntro && (
        <Fade
          in={mounted}
          timeout={{
            appear: 1000,
            enter: 300,
            exit: 300,
          }}
        >
          <Box>
            {question && slidersQuestions?.length <= 0 && (
              <Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  marginTop="auto"
                  flexWrap="wrap"
                  paddingTop={3}
                >
                  {question?.questions?.[0]?.previousGroup &&
                    (onBoarding
                      ? question?.questions?.[0]?.previousGroup?.split(
                          "_"
                        )[1] !== "01"
                      : true) && (
                      <Button
                        onClick={handlePreviousTest}
                        sx={{
                          m: 1,
                          width: "240px",
                          height: "53px",
                          fontSize: "14px",
                        }}
                        variant="outlined"
                        color="secondary"
                      >
                        {t("btn.prev")}
                      </Button>
                    )}
                  <Button
                    sx={{
                      width: "240px",
                      height: "53px",
                      fontSize: "14px",
                      m: 1,
                    }}
                    variant="contained"
                    onClick={handleNextTest}
                  >
                    {t(
                      `btn.${
                        question?.questions?.[0]?.code === "693"
                          ? "valid"
                          : "next_quest"
                      }`
                    )}
                  </Button>
                </Box>
                {progress < 99 && (
                  <Box display="flex" justifyContent="center" sx={{ m: 1 }}>
                    <Button
                      sx={{
                        width: "240px",
                        height: "53px",
                        fontSize: "14px",
                        color: "#8F9BB3",
                      }}
                      onClick={() => setOpenModal(true)}
                    >
                      {t("tests.modal.do_later")}
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Fade>
      )}
      <FinishLaterTestModal
        open={openModal}
        close={() => setOpenModal(false)}
        finishLater={() => finishLater()}
      />
    </Box>
  );
};

export default IncTestPart;
